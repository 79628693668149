import { Suspense, useEffect } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import Layout from "../layout/Layout"
import Home from "../home/Home"
import { Box, CircularProgress } from "@mui/material"
import LoginPage from "./login/LoginPage"
import RegisterPage from "./register/RegisterPage"
import Markets from "./leftsidebarpages/markets/Markets"
import News from "./leftsidebarpages/News"
import LiveCasino from "./leftsidebarpages/LiveCasino"
import SportsBar from "../../common/SportsBar"
import LiveCardPage from "./leftsidebarpages/livecardpage/LiveCard"
import Oops404Page from "./404/Oops404Page"
import SevenUpSevenDown from "./SevenUpSevenDown"
import ThirtytwoCards from "./ThirtytwoCards"
import WorliMatka from "./WorliMatka"
import AmarAkbharAnthony from "./AmarAkbharAnthony"
import T20TeenPatti from "./T20TeenPatti"
import Poker from "./Poker"
import BetsPage from "./bets/BetsPage"
import AboutPage from "./leftsidebarpages/about/AboutPage"
import PrivacyPolicy from "./leftsidebarpages/privacypolicy/PrivacyPolicy"
import FaqPage from "./leftsidebarpages/faq/FaqPage"
import Tutorials from "./leftsidebarpages/tutorials/Tutorials"
import Promotion from "./promotion/Promotion"
import Affiliate from "./leftsidebarpages/affiliate/Affiliate"
import TermCondition from "./leftsidebarpages/termcondition/TermCondition"
import SportsPage from "./sports/SportsPage"
import SportsEventDetail from "./sportdetails/SportsEventDetail"
import HorseRacing from "./horseracing/HorseRacing"
import HorseRacingDetails from "./horseracing/HorseRacingDetails"
import GreyHoundRacing from "./greyhoundracing/GreyHoundRacing"
import GreyHoundRacingDetails from "./greyhoundracing/GreyHoundRacingDetails"
import LiveCasinoGame from "./LiveCasinoGame"
import CrashCasinoGame from "./CrashCasinoGame"
import LiveCasinoCard from "./LiveCasinoCard"
import SlotCasinoGames from "./SlotCasinoGames"
import Matka from "./Matka"
import ResetPassword from "./rightsidebarpages/resetpassword/ResetPassword"
import Profile from "./rightsidebarpages/profile/Profile"
import ProfitLoss from "./rightsidebarpages/profitloss/ProfitLoss"
import AccountStatements from "./rightsidebarpages/accountstatement/AccountStatements"
import ChangeMobileNo from "./rightsidebarpages/changemobileno/ChangeMobileNo"
import SettledBets from "./rightsidebarpages/settledbets/SettledBets"
import CricketFight from "./rightsidebarpages/CricketFight"
import Favoruites from "./rightsidebarpages/Favoruites"
import RulesAndRegulation from "./rightsidebarpages/RulesAndRegulation"
import StakeSettings from "./rightsidebarpages/stakesetting/StakeSettings"
import ProfitLossDetails from "./rightsidebarpages/profitloss/ProfitLossDetails"
import { useIsSelfByAppUrlMutation } from "../../api/mainApi/mainApiSlice"
import Deposit from "./Deposit"
import Withdraw from "./Withdraw/Withdraw"

const Pages = () => {
  const { pathname } = useLocation()
  const [trigger, { data: isSelfData }] = useIsSelfByAppUrlMutation()

  useEffect(() => {
    trigger({
      appUrl: window.location.hostname.replace("www.", ""),
      // appUrl: "fairplays24.com",
      // appUrl: "maggibook.com",
    })
  }, [])

  // const navigate = useNavigate()

  // const protectedroutetoken = localStorage.getItem("token")

  // useEffect(() => {
  //   if (protectedroutetoken) {
  //     if (pathname.includes("login")) {
  //       navigate("/")
  //     }
  //   } else {
  //     navigate("/login")
  //   }
  // }, [protectedroutetoken, pathname, navigate])

  return (
    <Suspense
      fallback={
        <Box>
          <CircularProgress size={"20px"} color="primary" />
        </Box>
      }
    >
      <Routes>
        <Route
          path=""
          element={<Layout isSelfData={isSelfData} currpathname={pathname} />}
        >
          <Route path="login" element={<LoginPage isSelfData={isSelfData} />} />
          <Route
            path="register"
            element={<RegisterPage isSelfData={isSelfData} />}
          />
          <Route path="" element={<SportsBar />}>
            <Route path="" element={<Home />} />
            <Route path="/markets" element={<Markets />} />
            <Route path="/markets/live-casino" element={<LiveCasino />} />
            <Route path="/markets/live-card" element={<LiveCardPage />} />
            <Route
              path="/markets/oneday-teen-patti"
              element={<Oops404Page />}
            />
            <Route path="/markets/t20-teen-patti" element={<T20TeenPatti />} />
            <Route path="/markets/andhar-bahar" element={<Oops404Page />} />
            <Route path="/markets/7up7down" element={<SevenUpSevenDown />} />
            <Route path="/markets/32-cards" element={<ThirtytwoCards />} />
            <Route path="/markets/dragon-tiger" element={<Oops404Page />} />
            <Route path="/markets/poker" element={<Poker />} />
            <Route
              path="/markets/amar-akbar-antony"
              element={<AmarAkbharAnthony />}
            />
            <Route path="/markets/worli-matka" element={<WorliMatka />} />
            <Route path="/bets" element={<BetsPage />} />
            <Route path="/news" element={<News />} />
            <Route path="/faqs" element={<FaqPage />} />
            <Route path="/tutorials" element={<Tutorials />} />
            <Route path="/promotion" element={<Promotion />} />
            <Route path="/affiliate" element={<Affiliate />} />
            <Route path="/term_condition" element={<TermCondition />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/aboutus" element={<AboutPage />} />
            <Route path="/sports/:sportname/:id" element={<SportsPage />} />
            <Route
              path="/sports-event-detail/:matchid/:id"
              element={<SportsEventDetail />}
            />
            <Route
              path="/racing-category/HORSE_RACE/:id"
              element={<HorseRacing />}
            />
            <Route
              path="/racing-list-details/HORSE_RACE"
              element={<HorseRacingDetails />}
            />
            <Route
              path="/racing-category/GREY_HOUND_RACING"
              element={<GreyHoundRacing />}
            />
            <Route
              path="/racing-list-details/GREY_HOUND_RACING"
              element={<GreyHoundRacingDetails />}
            />

            <Route path="/live-casino" element={<LiveCasinoGame />} />
            <Route path="/crash-games" element={<CrashCasinoGame />} />
            <Route path="/live-card" element={<LiveCasinoCard />} />
            <Route path="/slot-games" element={<SlotCasinoGames />} />
            <Route path="/matka" element={<Matka />} />

            <Route path="/account-statements" element={<AccountStatements />} />
            <Route path="/profit-loss" element={<ProfitLoss />} />
            <Route path="/deposit" element={<Deposit />} />
            <Route path="/withdraw" element={<Withdraw />} />
            <Route
              path="/profit-loss-details"
              element={<ProfitLossDetails />}
            />
            <Route path="/transaction" />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/change-mobile-no" element={<ChangeMobileNo />} />
            <Route path="/settled-bets" element={<SettledBets />} />
            {/* <Route path="/cricket-fight" element={<CricketFight />} /> */}
            <Route path="/favourites" element={<Favoruites />} />
            <Route path="/notifications" />
            <Route path="/rules-regulations" element={<RulesAndRegulation />} />
            <Route path="/stake-settings" element={<StakeSettings />} />

            <Route path="*" element={<Oops404Page />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  )
}

export default Pages
